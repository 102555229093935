import { LoadableAfterPaint } from '@confluence/loadable';

import type { SatisfactionSurveyComponentProps } from './SatisfactionSurveyComponent';
import type { SatisfactionSurveyFlagProps } from './SatisfactionSurveyFlag';
import type { SatisfactionSurveyModalProps } from './SatisfactionSurveyModal';
import type { SatisfactionSurveyOnlyTextboxComponentProps } from './SatisfactionSurveyOnlyTextboxComponent';

export const SatisfactionSurveyFlag = LoadableAfterPaint<SatisfactionSurveyFlagProps>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SatisfactionSurveyFlag" */ './SatisfactionSurveyFlag'
			)
		).SatisfactionSurveyFlag,
});

export const SatisfactionSurveyComponent = LoadableAfterPaint<SatisfactionSurveyComponentProps>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SatisfactionSurveyComponent" */ './SatisfactionSurveyComponent'
			)
		).SatisfactionSurveyComponent,
});

export const SatisfactionSurveyModal = LoadableAfterPaint<SatisfactionSurveyModalProps>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SatisfactionSurveyModal" */ './SatisfactionSurveyModal'
			)
		).SatisfactionSurveyModal,
});

export const SatisfactionSurveyOnlyTextBoxComponent =
	LoadableAfterPaint<SatisfactionSurveyOnlyTextboxComponentProps>({
		loader: async () =>
			(
				await import(
					/* webpackChunkName: "loadable-SatisfactionSurveyOnlyTextBoxComponent" */ './SatisfactionSurveyOnlyTextboxComponent'
				)
			).SatisfactionSurveyOnlyTextBoxComponent,
	});

export { REACTIONS } from './reactions';

export type {
	OnInteractionData,
	SatisfactionSurveyComponentProps,
} from './SatisfactionSurveyComponent';
export type { SatisfactionSurveyFlagProps } from './SatisfactionSurveyFlag';
export type { SatisfactionSurveyModalProps } from './SatisfactionSurveyModal';
