import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';

import type { FlagsStateContainer, FlagDescriptor } from '@confluence/flags';

import {
	foldTimeout,
	getAKTimeout,
	getSecondsBetweenNowAndTime,
	refreshAKTimeout,
} from './timeoutUtils';
import { Counter } from './Counter';

const i18n = defineMessages({
	timeoutSuperAdminTitle: {
		id: 'super-admin.timeout.super.admin.title',
		defaultMessage: 'Still there?',
		description: 'Title for the flag for Admin Key expiry notification',
	},
	superAdminTimerFlagAction: {
		id: 'super-admin.timer.flag.action',
		defaultMessage: 'Keep using admin key',
		description: 'Text for the action on Super Admin timeout flag',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlagTimerDiv = styled.div({
	fontWeight: token('font.weight.bold'),
	display: 'inline',
});

type SuperAdminExpirationTimerProps = {
	flags: FlagsStateContainer;
	disableSuperAdminTrigger: () => void;
};

/**
 * This component handles the AK Expiration timeout, and takes actions according to the timeout.
 * NOTE: It is important that this component is only mounted once. It could cause multiple timeouts running in the window.
 */
export const SuperAdminExpirationTimer: FC<SuperAdminExpirationTimerProps> = ({
	flags,
	disableSuperAdminTrigger,
}: SuperAdminExpirationTimerProps) => {
	const [flagId, setFlagId] = useState<number | string | undefined>();
	// The various callback functions that are passed to foldTimeout() have their function's scope
	// frozen at the point in time when they are passed to foldTimeout() and thus only see the values
	// of the state variables (like flagId) at that time and don't see any changes to those state
	// variables. But references are basically const (fixed) even though their values can change and
	// we can update it on every render pass, so hideFlag() can use that reference so it always sees
	// the up-to-date value for flagId. For more details, see Dan Abramov's blog post on this at
	// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
	const flagIdRef = useRef<number | string | undefined>();
	flagIdRef.current = flagId;

	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const waitFor1Second = (callback) => {
		setTimeout(callback, 1000);
	};

	const showFlag = (timeout: number) => {
		void flags
			.showWarningFlag({
				title: intl.formatMessage(i18n.timeoutSuperAdminTitle),
				description: renderFlagDescription(timeout),
				actions: [
					{
						content: intl.formatMessage(i18n.superAdminTimerFlagAction),
						onClick: onClickKeepUsing(),
					},
				],
			})
			.then((newFlag: FlagDescriptor) => setFlagId(newFlag.id));
	};

	const hideFlag = () => {
		if (flagIdRef.current !== undefined) {
			void flags.hideFlag(flagIdRef.current);
		}
	};

	const renderFlagDescription = (timeout: number) => (
		<div data-testid="super-admin.timeout.flag.description">
			<FormattedMessage
				id="super-admin.timeout.super.admin.description"
				defaultMessage="Due to inactivity, you will be signed out in {counter}."
				description="Description  for the flag for Admin Key expiry notification"
				values={{
					counter: (
						<FlagTimerDiv>
							<Counter initialCountdown={getSecondsBetweenNowAndTime(timeout)} />
						</FlagTimerDiv>
					),
				}}
			/>
		</div>
	);

	const onClickKeepUsing = () => () => {
		refreshAKTimeout();
		fireAdminKeyExtendedAnalyticsEvent();
		hideFlag();
	};

	const fireAdminKeyExtendedAnalyticsEvent = () => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: 'superAdminExpiration',
				action: 'extended',
				actionSubject: 'adminKeySession',
			},
		}).fire();
	};

	/**
	 * This function is recursively called.
	 * It checks the current state of AK Timeout and takes actions accordingly.
	 */
	const handleAKTimeout = (flagShownAlready: boolean) => {
		foldTimeout(getAKTimeout())({
			beforeNotify: () =>
				waitFor1Second(() => {
					if (flagShownAlready) {
						hideFlag();
					}
					handleAKTimeout(false);
				}),
			afterNotify: (timeout) =>
				waitFor1Second(() => {
					if (!flagShownAlready) {
						showFlag(timeout);
					}

					handleAKTimeout(true);
				}),
			afterExpiry: () => disableSuperAdminTrigger(),
			noTimeout: () => {
				/* No timeout means Admin Key isn't enabled, so do nothing */
			},
		});
	};

	useEffect(
		() => {
			// Set up the timer only once
			handleAKTimeout(false);
		},
		// Note we can not include "flags" or anything that is dependent on it in the dependency list,
		// because every time the FlagsStateContainer changes we get a new version of "flags", which
		// would force the timer to be reset in an infinite loop.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[disableSuperAdminTrigger],
	);

	return null;
};
